<template>
  <div>
    <project-field-interested :type="type"></project-field-interested>
  </div>
</template>

<script>
import projectFieldInterested from 'views/main/basicPage/_components/projectFieldInterested';
export default {
  components: {
    projectFieldInterested
  },
  data() {
    return {
      type: 1
    };
  }
};
</script>
